@tailwind base;
@tailwind components;
@tailwind utilities;

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
    display: block;
}
body {
    line-height: 1;
}
ol, ul {
    list-style: none;
}
ul.list-disc {
    list-style: disc;
}
ul.list-disc li {
    margin-left: 22px;
}
blockquote, q {
    quotes: none;
}
blockquote:before, blockquote:after, q:before, q:after {
    content: '';
    content: none;
}
table {
    border-collapse: collapse;
    border-spacing: 0;
}

ul.dot li {
    padding-left: 20px
}

ul.dot li:before {
    position: relative;
    top: -4px;
    left: -10px;
    display: inline-block;
    width: 2px;
    height: 2px;
    background-color: black;
    content: '';
}

.float-label-input:focus-within label,
.float-label-input input:not(:placeholder-shown) + label,
.float-label-input.isValue label {
    transform: translateY(-1.5rem);
    font-size: 12px;
}

.border-b-3px {
    border-bottom-width: 3px;
}

.menulink:hover {
    border-bottom-width: 3px;
}